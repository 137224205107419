#root {
  /* background-image: url("../public/bg.png"); */
}

.container-page {
  padding: 0 !important;
  background-image: url("../public/bg.png");
  background-repeat: no-repeat;
}

.outer-container {
  background-image: url("../public/bg-inner.jpeg");
  width: 100%;
  aspect-ratio: 16/9;
}

.inner-container {
  background-image: url("../public/mv_01.jpg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.card-container {
  width: 100%;
  background-image: url("../public/bg.png");
  text-align: center;
}

.card-list {
  padding: 16px;
  padding-top: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 16px;
}

.card-item {
  position: relative;
  background: white;
  border-radius: 12px;
}

.card-item img {
  object-fit: contain;
  aspect-ratio: 1/1;
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.card-item img.card-img {
  aspect-ratio: 3/4;
  object-fit: fill;
}

.card-info {
  text-align: center;
  padding: 8px 16px;
}

.card-label {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 10px;
  font-weight: 600;
  background: red;
  padding: 4px 8px;
  border-radius: 4px;
}

.feedback {
  margin: 16px 0;
  background-image: url("../public/bg-one.webp");
  background-blend-mode: luminosity;
}

.my-contact-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding-bottom: 32px;
}

.my-contact-container img {
  width: 300px;
  object-fit: contain;
  border-radius: 16px;
}
